import { RootState } from "app/rootReducer";
import { TabContext } from "components/Layout/LayoutWrapper";
import { genTabId, removeTab, setTabName } from "components/Tabs/tabSlice";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entityConfigs, EntityData, EntityType, getModel } from "utils/entitySlice";
import { assertNever } from "utils/functions";
import { AddForm } from "utils/models/AddForm";
import { fillDefaultsByPath, generateDefault } from "utils/models/formGenerator";
import { ButtonProperties } from "utils/models/SubmitButton";
import { v4 as uuidv4 } from "uuid";

export const selectorFunction = (type: EntityType, requestId: string) => {
  switch (type) {
    case "contract":
      return (state: RootState) => state.addContractSlice[requestId];
    case "contract_type":
      return (state: RootState) => state.addContractTypeSlice[requestId];
    case "applicant":
      return (state: RootState) => state.addApplicantSlice[requestId];
    case "deal":
      return (state: RootState) => state.addDealSlice[requestId];
    case "dealership":
      return (state: RootState) => state.addDealershipSlice[requestId];
    case "dmv":
      return (state: RootState) => state.addDmvSlice[requestId];
    case "custom_report":
      return (state: RootState) => state.addCustomReportSlice[requestId];
    case "business_report":
      return (state: RootState) => state.addBusinessReportSlice[requestId];
    case "external_credit_application":
      return (state: RootState) => state.addExternalCreditApplicationSlice[requestId];
    case "wfd_user":
      return (state: RootState) => state.addUserSlice[requestId];
    case "lender":
      return (state: RootState) => state.addLenderSlice[requestId];
    case "role":
      return (state: RootState) => state.addRoleSlice[requestId];
    case "dealership_program":
      return (state: RootState) => state.addDealershipProgramSlice[requestId];
    case "manager_program":
      return (state: RootState) => state.addManagerProgramSlice[requestId];
    case "document_template":
      return (state: RootState) => state.addDocumentTemplateSlice[requestId];
    case "document_validation":
      return (state: RootState) => state.addValidationSlice[requestId];
    case "appone_import_application":
      return (state: RootState) => state.addAppOneDealSlice[requestId];
    case "dealertrack_deal":
      return (state: RootState) => state.addDealerTrackSlice[requestId];
    case "payoff_bank":
      return (state: RootState) => state.addPayoffBankSlice[requestId];
    case "other_vendor":
      return (state: RootState) => state.addOtherVendorSlice[requestId];
    case "state":
      return (state: RootState) => state.addStateSlice[requestId];
    case "chargeback":
      return (state: RootState) => state.addChargebackSlice[requestId];
    case "vehicle_insurance_company":
      return (state: RootState) => state.addVehicleInsuranceCompanySlice[requestId];
    case "file":
      return (state: RootState) => state.addFileSlice[requestId];
    case "funding_document":
      return (state: RootState) => state.addFundingDocumentSlice[requestId];
    case "additional_required_document":
      return (state: RootState) => state.addAdditionalRequiredDocumentSlice[requestId];
    case "notification":
    case "lender_decision":
      return (state: RootState) => state.addLenderDecisionSlice[requestId];
    case "dealership_bills_report":
      return (state: RootState) => state.addDealershipBillsSlice[requestId];
    case "credit_score_range":
      return (state: RootState) => state.addCreditScoreRangeSlice[requestId];
    case "lender_ticket_type":
      return (state: RootState) => state.addLenderTicketTypeSlice[requestId];
    case "lender_ticket":
      return (state: RootState) => state.addLenderTicketSlice[requestId];
    case "checklist":
    case "column_group":
    case "note":
    case "customer_care_note":
    case "sequence":
    case "unapproved_user":
    case "floorplanxpress":
    case "lock":
    case "credit_score":
    case "title_issue":
    case "dealership_ticket":
    case "table_settings":
    case "history":
    case "settings":
    case "delivered_deal":
    case "api_status":
    case "one_span_signing":
    case "unparsed_required_document":
    case "printed_document":
    case "netsuite":
    case "dealer_note":
    case "dealership_ticket_note":
    case "title_issue_note":
    case "lender_note":
    case "stipulation":
      return;

    default:
      return assertNever(type);
  }
};

interface Props {
  type: EntityType;
  defaultState?: any;
  openInNewTab?: boolean;
  initialStateModifier?: <T>(x: T) => T;
  callback?: (x: any) => void;
  buttonProps?: ButtonProperties;
}
export default ({
  type,
  defaultState = null,
  openInNewTab = true,
  initialStateModifier = (x) => x,
  callback = (_) => {},
  buttonProps
}: Props) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const createOrFocusTab = useContext(TabContext);
  //@ts-ignore
  const addingState = useSelector(selectorFunction(type, requestId));

  useEffect(() => {
    if (
      addingState !== undefined &&
      addingState.status === "success" &&
      addingState.data !== null
    ) {
      if (openInNewTab) {
        dispatch(removeTab(genTabId("addPage", { type })));
        createOrFocusTab({
          label: "Show page",
          index: "showPage",
          isForSidebar: false,
          isForQuickAccess: false,
          isPersistent: false,
          props: {
            _id: addingState.data.message._id,
            type
          }
        });
      }
      callback(addingState);
    }
  }, [addingState, createOrFocusTab, dispatch, type, openInNewTab, callback]);

  useEffect(() => {
    dispatch({
      type: entityConfigs[type].edit.sliceActions.add.type,
      payload: { requestId }
    });
    return () => {
      dispatch({
        type: entityConfigs[type].edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch, type]);

  useEffect(() => {
    dispatch(
      setTabName({
        _id: genTabId("addPage", { type }),
        name: `New - ${type}`
      })
    );
  }, [dispatch, type]);
  const [canSubmit, setCanSubmit] = useState(true);
  const handleSubmit = (state: EntityData<typeof type>) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (canSubmit) {
      const { availableToRoles, ...data } = state;
      const roleIds = availableToRoles?.map((role: any) => role._id) ?? [];
      setCanSubmit(false);
      const addFunction = entityConfigs[type].add.do;
      const validate = entityConfigs[type].edit.validate;
      const validateResult = validate?.(state);
      if (!validate || validateResult?.valid) {
        dispatch(addFunction({ ...(data as any), availableToRolesIds: roleIds, requestId }));
        setTimeout(() => {
          setCanSubmit(true);
        }, 2000);
      } else {
        alert(validateResult?.message);
      }
    } else {
      alert("Please dont submit the form twice!");
    }
  };

  const model = getModel(type);
  const userPermissions = useSelector((state: RootState) => state?.authSlice?.user?.permissions);

  const generatedDefaultState = initialStateModifier(
    generateDefault(model as any, {}, fillDefaultsByPath as any, userPermissions?.[model.name])
  );

  return (
    <AddForm
      canSubmit={canSubmit}
      buttonProps={buttonProps}
      initialState={generatedDefaultState}
      model={model as any}
      handleSubmit={handleSubmit}
    />
  );
};
