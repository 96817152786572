import { CrudEntity } from "utils/types";
import { Dealership } from "../Dealerships/types";
import { CreditBureauRequest } from "./checkCreditScoreSlice";

export enum CreditCheckTypes {
  Applicant = "applicant",
  CoApplicant = "coApplicant",
  Joint = "joint"
}

export enum CreditCheckMode {
  Hard = "CREDIT",
  Soft = "PREQUALIFY"
}

export type SuccessResponse = {
  bureau_xml_data: [
    {
      TU_Report: [
        {
          subject_segments: {
            scoring_segments: [
              {
                scoring: [{ score: string[]; ScoreRange: string[]; ScoreRank: string[] }];
                Scoring: [{ Score: string[] }];
              }
            ];
          }[];
        }
      ];
    }
  ];
  custom_report_url: [
    {
      iframe: [
        {
          attributes: {
            src: string;
          };
        }
      ];
    }
  ];
  Transaction_Information: [{ DuplicateFound: [string] }];
  custom_report: string[];
};

export type CreditBureau = CrudEntity & {
  data: CreditBureauData;
};

export interface CreditBureauData {
  type: CreditCheckTypes;
  dealId: string;
  applicantId: string;
  coApplicantId: string;
  "700CreditResponse": {
    Results?: SuccessResponse;
  };
  dealershipId: string;
  dealership: Dealership;
  request: CreditBureauRequest;
  source: "700Credit" | "local";
  createdAt?: string;
}

// export const getScoreFromSuccessResponsePrequalify = (
//   response: SuccessResponsePrequalify | undefined
// ) => {
//   return response?.XML_Report?.[0]?.Prescreen_Report?.[0]?.Score?.[0] || "";
// };

// export const getScoreFromSuccessResponse = (response: SuccessResponse | undefined) => {
//   return response?.bureau_xml_data?.[0]?.TU_Report?.[0]?.subject_segments?.[0]
//     ?.scoring_segments?.[0]?.scoring?.[0];
// };

// export const getScoreFromBureau = (creditBureau: CreditBureau | undefined) => {
//   return creditBureau?.data.apiType === "PREQUALIFY"
//     ? getScoreFromSuccessResponsePrequalify(
//         creditBureau?.data["700CreditResponse"]?.Results as SuccessResponsePrequalify
//       )
//     : getScoreFromSuccessResponse(
//         creditBureau?.data["700CreditResponse"]?.Results as SuccessResponse
//       );
// };
