import DateFnsUtils from "@date-io/date-fns";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Cleave from "cleave.js/react";
import { HintTooltip } from "components/common/HintTooltip";
import { FormEditContext } from "components/Content/FormEditContext";
import { SearchContext } from "components/Layout/Main";
import { Permission } from "components/Roles/types";
import { User } from "components/Users/types";
import ZipCode from "components/ZipCodeLookup/ZipCode";
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useContext, useEffect, useState } from "react";
import formatDate from "utils/formatDate";
import { removeSpaces } from "utils/functions";
import toCamelCase from "utils/toCamelCase";
import { validateSchema } from "validations";
import { RequestType } from "validations/types";
import {
  CheckboxField,
  DateField,
  DateWithAge,
  DurationField,
  Field,
  FieldWidth,
  filePreviewField,
  FormComponent,
  ListModel,
  Model,
  MultiSelectField,
  MultiTextInputField,
  NumberInputField,
  Path,
  RadioEntry,
  RadioField,
  RawField,
  ReadOnlyListModel,
  Segment,
  SelectField,
  TabListModel,
  TextInputField,
  Timeline as TimelineField,
  TimelineItem,
  VirtualField,
  ZipCodeField
} from "./fields";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  ParentPath,
  RenderSet,
  StateAccess
} from "./formGenerator";

import { RootState } from "app/rootReducer";
import { getFieldWarning } from "components/Settings/modelToRangeFields";
import { useSnackbar } from "notistack";
import ReactApexChart from "react-apexcharts";
import { State } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import UnlockPINDialog from "components/Users/UnlockPINDialog";

const calculateSize = (width: FieldWidth, stateAccess: StateAccess) => {
  const stringWidth = typeof width === "function" ? width(stateAccess) : width;
  if (typeof width === "number") return width;
  switch (stringWidth) {
    case "1/6":
      return 2;
    case "1/4":
      return 3;
    case "3/4":
      return 9;
    case "1/3":
      return 4;
    case "2/3":
      return 8;
    case "1/2":
      return 6;
    case "full":
      return 12;
    case "auto":
      return true;
    case "hidden":
      return false;
  }
};
export const renderRadioField = <T extends any>(
  props: RadioField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  formEditContext?: FormEditContext
) => {
  let disabledValues: { value: string; title: string }[] = [];
  if (props && props.disabledValues) {
    disabledValues = props.disabledValues(stateAccess, formEditContext);
  }
  return (
    <FormControl
      component="fieldset"
      key={props.name + props.path}
      style={props?.conditionalStyle ? props.conditionalStyle(stateAccess) : {}}
    >
      <FormLabel
        component="legend"
        required={
          typeof props.required === "function"
            ? props.required(stateAccess)
            : props.required ?? false
        }
        style={{ fontSize: "15px" }}
      >
        {props.label}
        {props?.labelElement?.(stateAccess)}
      </FormLabel>
      <RadioGroup
        aria-label={props.name}
        value={stateAccess.get(props.path)}
        onChange={(event: any) => {
          if (props.onChange) props.onChange(stateAccess, event.target.value);
          stateAccess.set(props.path, event.target.value);
        }}
      >
        <Box style={{ display: "flex", flexWrap: "wrap" }}>
          {props.possibleValues.map(
            ({ value, label, color, labelElement, labelStyle }: RadioEntry, index) => {
              const disabledValue = disabledValues.find(
                ({ value: disabledValue }) => disabledValue === value
              );
              const isDisabled = typeof disabledValue === "object";
              const radioTitle = props.radioTitle ? props.radioTitle(stateAccess, value) : "";
              return (
                <FormControlLabel
                  id={`${props?.path?.join(".")}:${label}-${props?.label}`}
                  key={index}
                  style={labelStyle}
                  value={value}
                  control={
                    <Radio
                      required={
                        typeof props.required === "function"
                          ? props.required(stateAccess)
                          : props.required ?? false
                      }
                      color="primary"
                    />
                  }
                  label={
                    <>
                      {label} {labelElement?.(stateAccess)}
                    </>
                  }
                  disabled={isDisabled}
                  title={isDisabled ? radioTitle + "\n" + disabledValue?.title : radioTitle}
                />
              );
            }
          )}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export const renderSimpleRadioField = <T extends any>(
  props: RadioField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  formEditContext?: FormEditContext
) => {
  return (
    <FormControl component="fieldset" key={props.name}>
      <FormLabel
        component="legend"
        required={
          typeof props.required === "function"
            ? props.required(stateAccess)
            : props.required ?? false
        }
        style={{ fontSize: "15px" }}
      >
        {props.label}
        {props?.labelElement?.(stateAccess)}
      </FormLabel>
      <RadioGroup aria-label={props.name} value={stateAccess.get(props.path)}>
        <Box style={{ display: "flex", flexWrap: "wrap" }}>
          {props.possibleValues.map(
            ({ value, label, color, labelElement, labelStyle }: RadioEntry, index) => {
              return (
                <FormControlLabel
                  id={`${props?.path?.join(".")}:${label}-${props?.label}`}
                  key={index}
                  value={value}
                  disabled
                  style={{ color, ...labelStyle }}
                  control={
                    <Radio
                      required={
                        typeof props.required === "function"
                          ? props.required(stateAccess)
                          : props.required ?? false
                      }
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <>
                      {label}
                      {labelElement?.(stateAccess)}
                    </>
                  }
                />
              );
            }
          )}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export const renderSelectField = <T extends any>(
  props: SelectField<T>,
  stateAccess: StateAccess,
  path: string[],
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <Autocomplete
      autoSelect={props.autoSelect}
      onOpen={props.onOpen}
      options={props.options.possibleValues(stateAccess, path, mainStateAccess) ?? []}
      autoHighlight
      openOnFocus
      fullWidth
      loading={!props.options.possibleValues(stateAccess, path, mainStateAccess)}
      freeSolo={props.options.freeSolo}
      getOptionLabel={(x) => props.options.getOptionLabel(x) ?? ""}
      getOptionSelected={props.options.getSelectedOption ?? ((a, b) => a === b)}
      value={stateAccess.get(props.path) ?? null}
      key={props.name}
      onInputChange={(e, value) =>
        props.options.onInputChange ? props.options.onInputChange(value, stateAccess) : undefined
      }
      id={`${props?.path?.join(".")}:${props?.label === "" ? "f&i manager" : props?.label}`}
      renderOption={
        props?.options?.renderOption
          ? (option, state) =>
              props?.options?.renderOption?.(option, state, props.options.getOptionLabel)
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          style={props?.conditionalStyle ? props.conditionalStyle(stateAccess) : {}}
          InputLabelProps={{ shrink: true }}
          InputProps={{ ...params.InputProps, style: props.style }}
          required={
            typeof props.required === "function"
              ? props.required(stateAccess)
              : props.required ?? false
          }
          label={props.label}
          variant="filled"
          size="small"
          error={props?.error}
          helperText={props?.helperText}
        />
      )}
      onChange={(event: any, value: string | null) => {
        if (props.options.numeric === true && value !== null) {
          return stateAccess.set(props.path, parseFloat(value));
        } else {
          return stateAccess.set(props.path, value);
        }
      }}
    />
  );
};

export const renderSimpleCheckboxField = <T extends any>(
  props: CheckboxField<T>,
  stateAccess: StateAccess,
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  let isChecked = props.isChecked(stateAccess);
  if (typeof isChecked === "string") {
    isChecked = isChecked === "true";
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.isDisabled ? props.isDisabled(stateAccess) : false}
          id={`${props?.path?.join(".1")}:${props?.label}`}
          checked={isChecked}
        />
      }
      label={props.label}
    />
  );
};

export const renderCheckboxField = <T extends any>(
  props: CheckboxField<T>,
  stateAccess: StateAccess,
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  const isChecked = props?.isChecked(stateAccess);
  const isIndeterminate = props?.isIndeterminate ? props?.isIndeterminate(stateAccess) : false;
  const isDisabled = props?.isDisabled ? props?.isDisabled(mainStateAccess) : false;
  return (
    <FormControlLabel
      style={props.style}
      key={`${props?.path?.join(".")}:${props?.label}${isChecked}`}
      control={
        <Checkbox
          style={props.style}
          id={`${props?.path?.join(".")}:${props?.label}`}
          disabled={isChecked ? false : isDisabled}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={(_, checked) => props.toggle(stateAccess, checked)}
        />
      }
      label={props.label}
    />
  );
};
export const renderMultiTextInputField = <T extends any>(
  props: MultiTextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  digitsOnlyField = false,
  parentPath?: ParentPath<T>
) => {
  return (
    <MultiTextInput
      stateAccess={stateAccess}
      renderSet={renderSet}
      digitsOnlyField={digitsOnlyField}
      parentPath={parentPath}
      {...props}
    />
  );
};
export const renderMultiPhoneInputField = <T extends any>(
  props: MultiTextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  return (
    <MultiPhoneInput
      stateAccess={stateAccess}
      renderSet={renderSet}
      parentPath={parentPath}
      {...props}
    />
  );
};
export const renderMultiSelectField = <T extends any>(
  props: MultiSelectField<T>,
  stateAccess: StateAccess,
  path: string[],
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  const required =
    typeof props.required === "function" ? props.required(stateAccess) : props.required ?? false;
  return (
    <Autocomplete
      onOpen={props.onOpen}
      options={props.options.possibleValues(stateAccess, path, mainStateAccess)}
      autoHighlight
      openOnFocus
      multiple
      getOptionLabel={(x) => props.options.getOptionLabel(x) ?? ""}
      getOptionSelected={props.options.getSelectedOption ?? ((a, b) => a === b)}
      value={stateAccess.get(props.path) ?? []}
      key={props.name}
      id={`${props?.path?.join(".")}:${props?.label}`}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              style={{ maxWidth: "150px" }}
              key={index}
              {...getTagProps({ index })}
              label={props.options.getOptionLabel(option) ?? ""}
            />
          );
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            "data-value": JSON.stringify(stateAccess.get(props.path)),
            required: required ? (stateAccess.get(props.path) ?? []).length === 0 : false
          }}
          InputLabelProps={{ shrink: true }}
          required={required}
          label={props.label}
          variant="filled"
          fullWidth
          size="small"
        />
      )}
      onChange={(event: any, value: any) => {
        return stateAccess.set(props.path, value);
      }}
    />
  );
};
export function NumberInput(props: any) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      id={`${props?.path?.join(".")}:${props?.label}`}
      options={{
        numeral: true,
        numeralDecimalMark: ".",
        numeralDecimalScale: 4,
        delimiter: ","
      }}
      {...rest}
    />
  );
}

const renderPercentageField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <RenderPercentageField
      Props={props}
      stateAccessInitial={stateAccessInitial}
      renderSet={renderSet}
    />
  );
};
const buildSchemaPath = <T extends any>(
  parentPath: ParentPath<T>,
  path: Path<T>,
  stateAccess: StateAccess,
  request: RequestType | false
): ParentPath<T> => {
  const finalPath = parentPath.concat(path) as string[];
  switch (request) {
    case "update_deal":
      if (finalPath.includes("aftermarketOptions") && finalPath.includes("insurances")) {
        const currentInsurancePath = finalPath.slice(0, finalPath.indexOf("insurances") + 2);
        switch (stateAccess.get(currentInsurancePath)?.type) {
          case "F&I Express":
            finalPath.splice(finalPath.indexOf("insurances") + 2, 0, "anyOf/0");
            break;
          case "Protective":
            finalPath.splice(finalPath.indexOf("insurances") + 2, 0, "anyOf/1");
            break;
          case "Custom":
            finalPath.splice(finalPath.indexOf("insurances") + 2, 0, "anyOf/2");
            break;
          case "ASC":
            finalPath.splice(finalPath.indexOf("insurances") + 2, 0, "anyOf/3");
            break;
        }
      }
      break;
    case "appone_import_application":
      if (finalPath.includes("Borrower")) {
        finalPath.splice(
          finalPath.indexOf("Borrowers") +
            (Array.isArray(
              stateAccess.get(finalPath.slice(0, finalPath.indexOf("Borrower")) as Path<T>)
            )
              ? 2
              : 1),
          0,
          "anyOf/0"
        );
      }
      if (finalPath.includes("CoBorrower")) {
        finalPath.splice(
          finalPath.indexOf("Borrowers") +
            (Array.isArray(
              stateAccess.get(finalPath.slice(0, finalPath.indexOf("Borrower")) as Path<T>)
            )
              ? 2
              : 1),
          0,
          "anyOf/0"
        );
      }
      break;
    case false:
    default:
      break;
  }
  return finalPath as ParentPath<T>;
};
const RenderPercentageField = <T extends any>({
  Props,
  stateAccessInitial,
  renderSet,
  parentPath
}: {
  Props: NumberInputField<T>;
  stateAccessInitial: StateAccess;
  renderSet: RenderSet;
  parentPath?: ParentPath<T>;
}) => {
  const stateAccess = stateAccessInitial;
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];
  const valueBeforeCheck = stateAccess.get(Props.path);
  const internalState = Number.isNaN(parseFloat(valueBeforeCheck))
    ? Props.default
    : valueBeforeCheck;
  const decimalPlaces = internalState?.toString()?.split(".")[1]?.length;
  const [state, setState] = useState(
    parseFloat(internalState).toFixed(decimalPlaces > 1 ? decimalPlaces : 2)
  );

  useEffect(() => {
    if (internalState !== parseFloat(state))
      setState(parseFloat(internalState).toFixed(decimalPlaces > 1 ? decimalPlaces : 2));
  }, [internalState, setState, decimalPlaces, state]);

  const [validation, setValidation] = useState(
    validateSchema(renderSet.schema, internalState, [
      ...(parentPath ?? []),
      ...(Props.path as string[])
    ])
  );

  const warning = getFieldWarning(Props.name, internalState, settings);

  const error =
    internalState !== null && internalState !== undefined && validation?.valid === false;

  return (
    <TextField
      id={`${Props?.path?.join(".")}:${Props?.label}`}
      variant="filled"
      label={Props.label}
      required={
        typeof Props.required === "function" ? Props.required(stateAccess) : Props.required ?? false
      }
      type="text"
      size="small"
      style={Props?.conditionalStyle ? Props.conditionalStyle(stateAccess) : {}}
      fullWidth
      autoComplete="new-password"
      value={state}
      onBlur={() => {
        setValidation(
          validateSchema(renderSet.schema, internalState, [
            ...(parentPath ?? []),
            ...(Props.path as string[])
          ])
        );
        setState(parseFloat(internalState).toFixed(decimalPlaces > 1 ? decimalPlaces : 2));
      }}
      onChange={(event) => {
        const currentValue = parseFloat(event.target.value);

        if (Number.isNaN(parseFloat(event.target.value)))
          return stateAccess.set(Props.path, Props.default);
        if (event.target.value === "") return stateAccess.set(Props.path, Props.default);
        setValidation(
          validateSchema(renderSet.schema, internalState, [
            ...(parentPath ?? []),
            ...(Props.path as string[])
          ])
        );
        setState(event.target.value);
        stateAccess.set(Props.path, currentValue);
      }}
      InputProps={{
        inputComponent: NumberInput,
        startAdornment: <InputAdornment position="start">%</InputAdornment>
      }}
      InputLabelProps={{ shrink: true }}
      error={error}
      FormHelperTextProps={warning ? { style: { color: "#f19c65" } } : {}}
      helperText={
        error
          ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
          : warning
          ? warning
          : undefined
      }
    />
  );
};

const DecimalField = <T extends any>(
  props: NumberInputField<T> & {
    stateAccessInitial: StateAccess;
    renderSet: RenderSet;
    parentPath?: ParentPath<T>;
  }
) => {
  const stateAccess = props.stateAccessInitial;
  const [internalState, setInternalState] = useState(stateAccess.get(props.path));

  const [timeout, setTimeoutState] = useState<undefined | NodeJS.Timeout>();

  const globalState = stateAccess.get(props.path);
  useEffect(() => {
    if (globalState !== internalState) setInternalState(globalState);
    // eslint-disable-next-line
  }, [globalState]);
  const parentPath = props?.parentPath ?? [];
  const [validation, setValidation] = useState(
    internalState
      ? validateSchema(
          props?.renderSet?.schema as any,
          internalState,
          buildSchemaPath(parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
        )
      : { valid: true }
  );
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];

  const warning = getFieldWarning(props.name, internalState, settings);

  const error =
    internalState !== null && internalState !== undefined && validation?.valid === false;
  return (
    <CurrencyTextField
      modifyValueOnWheel={false}
      variant="filled"
      style={props?.conditionalStyle ? props.conditionalStyle(stateAccess) : {}}
      label={props.label}
      required={
        typeof props.required === "function" ? props.required(stateAccess) : props.required ?? false
      }
      size="small"
      currencySymbol={props.currencySymbol ?? ""}
      decimalPlaces={props.decimalPlaces ?? 2}
      outputFormat={props?.stringifyValue ? "string" : "number"}
      fullWidth
      id={`${props?.path?.join(".")}:${props?.label}`}
      // @ts-ignore
      overrideMinMaxLimits="invalid"
      maximumValue={props.max?.toString()}
      value={internalState}
      onBlur={() => {
        if (timeout) clearTimeout(timeout);
        setValidation(
          validateSchema(
            props?.renderSet?.schema as any,
            internalState,
            buildSchemaPath(parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
          )
        );
        if (globalState !== internalState) stateAccess.set(props.path, internalState);
      }}
      onChange={(event, value) => {
        setInternalState(value);
        if (timeout) clearTimeout(timeout);
        event.persist();
        setTimeoutState(
          setTimeout(() => {
            setValidation(
              validateSchema(
                props?.renderSet?.schema as any,
                internalState,
                buildSchemaPath(parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
              )
            );
            stateAccess.set(props.path, event.target.value === "" ? props.default : value);
          }, 500)
        );
      }}
      // @ts-ignore
      onKeyUp={(event) => {
        if (event.target.value === "") {
          stateAccess.set(props.path, props.default);
        }
      }}
      error={error}
      FormHelperTextProps={warning ? { style: { color: "#f19c65" } } : {}}
      helperText={
        error
          ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
          : warning
          ? warning
          : undefined
      }
    />
  );
};

const NormalNumberField = <T extends any>(
  props: NumberInputField<T> & {
    stateAccessInitial: StateAccess;
    renderSet: RenderSet;
    parentPath?: ParentPath<T>;
  }
) => {
  const stateAccess = props.stateAccessInitial;

  const [internalState, setInternalState] = useState(stateAccess.get(props.path));

  useEffect(() => {
    if (stateAccess.get(props.path) !== internalState) {
      setInternalState(stateAccess.get(props.path));
    }
  }, [props.path, stateAccess, internalState]);
  const [validation, setValidation] = useState(
    validateSchema(
      props.renderSet.schema,
      internalState,
      buildSchemaPath(props?.parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
    )
  );

  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];

  const warning = getFieldWarning(props.name, internalState, settings);

  const error =
    internalState !== null && internalState !== undefined && validation?.valid === false;
  return (
    <TextField
      id={`${props.path?.join(".")}:${props.label}`}
      variant="filled"
      label={props.label}
      required={
        typeof props.required === "function" ? props.required(stateAccess) : props.required ?? false
      }
      type="text"
      size="small"
      fullWidth
      autoComplete="new-password"
      value={internalState}
      onChange={(event) => {
        if (event.target.value.slice(-1) === ".") return;
        setInternalState(event.target.value);
        setValidation(
          validateSchema(
            props.renderSet.schema,
            internalState,
            buildSchemaPath(
              props?.parentPath ?? [],
              props.path,
              stateAccess,
              props.renderSet.schema
            )
          )
        );
        stateAccess.set(
          props.path,
          event.target.value ? parseFloat(event.target.value.toString().replace(/,/g, "")) : null
        );
      }}
      InputProps={{ inputComponent: NumberInput }}
      InputLabelProps={{ shrink: true }}
      error={error}
      FormHelperTextProps={warning ? { style: { color: "#f19c65" } } : {}}
      helperText={
        error
          ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
          : warning
          ? warning
          : undefined
      }
    />
  );
};

const MonthsField = <T extends any>(
  props: NumberInputField<T> & {
    stateAccessInitial: StateAccess;
    renderSet: RenderSet;
    parentPath?: ParentPath<T>;
  }
) => {
  const stateAccess = props.stateAccessInitial;
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];
  const [internalState, setInternalState] = useState(stateAccess.get(props.path));
  useEffect(() => {
    if (stateAccess.get(props.path) !== internalState) {
      setInternalState(stateAccess.get(props.path));
    }
  }, [props.path, stateAccess, internalState]);

  const [validation, setValidation] = useState(
    validateSchema(
      props?.renderSet?.schema as any,
      internalState,
      buildSchemaPath(props.parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
    )
  );

  const warning = getFieldWarning(props.name, internalState, settings);

  const error =
    internalState !== null && internalState !== undefined && validation?.valid === false;

  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      variant="filled"
      label={props.label}
      required={
        typeof props.required === "function" ? props.required(stateAccess) : props.required ?? false
      }
      type="text"
      size="small"
      fullWidth
      autoComplete="new-password"
      value={internalState}
      onChange={(event) => {
        if (event.target.value.slice(-1) === ".") return;
        setInternalState(event.target.value);
        setValidation(
          validateSchema(
            props?.renderSet?.schema as any,
            internalState,
            buildSchemaPath(props.parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
          )
        );
        const parsed = parseInt(event.target.value.toString().replace(/,/g, ""));
        stateAccess.set(
          props.path,
          event.target.value ? (!isNaN(parsed) && typeof parsed === "number" ? parsed : null) : null
        );
      }}
      FormHelperTextProps={warning ? { style: { color: "#f19c65" } } : {}}
      InputProps={{ inputComponent: NumberInput }}
      InputLabelProps={{ shrink: true }}
      error={error}
      helperText={
        error
          ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
          : warning
          ? warning
          : undefined
      }
    />
  );
};

const YearField = <T extends any>(
  props: NumberInputField<T> & {
    stateAccessInitial: StateAccess;
    renderSet: RenderSet;
    parentPath?: ParentPath<T>;
  }
) => {
  const stateAccess = props.stateAccessInitial;

  const [internalState, setInternalState] = useState(stateAccess.get(props.path));

  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];

  const warning = getFieldWarning(props.name, internalState, settings);

  useEffect(() => {
    if (stateAccess.get(props.path) !== internalState) {
      setInternalState(stateAccess.get(props.path));
    }
  }, [props.path, stateAccess, internalState]);

  const [validation, setValidation] = useState(
    validateSchema(
      props?.renderSet?.schema as any,
      internalState,
      buildSchemaPath(props.parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
    )
  );
  const error =
    internalState !== null && internalState !== undefined && validation?.valid === false;
  const setInRange = (value: string) => {
    const parsedValue = parseInt(value);
    if (props.max && props.min) {
      if (parsedValue > props.max) return props.max;
      if (parsedValue < props.min) return props.min;
      return parsedValue;
    }
    return parsedValue;
  };
  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      variant="filled"
      label={props.label}
      required={
        typeof props.required === "function" ? props.required(stateAccess) : props.required ?? false
      }
      type="number"
      size="small"
      fullWidth
      value={internalState == null ? "" : internalState}
      InputProps={{ inputProps: { min: props.min, max: props.max } }}
      InputLabelProps={{ shrink: true }}
      onChange={(event) => {
        setValidation(
          validateSchema(
            props?.renderSet?.schema as any,
            internalState,
            buildSchemaPath(props.parentPath ?? [], props.path, stateAccess, props.renderSet.schema)
          )
        );
        setInternalState(event.target.value);
        stateAccess.set(props.path, event.target.value ? setInRange(event.target.value) : null);
      }}
      error={error}
      FormHelperTextProps={warning ? { style: { color: "#f19c65" } } : {}}
      helperText={
        error
          ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
          : warning
          ? warning
          : undefined
      }
    />
  );
};

export const renderNumberInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  return (
    <NormalNumberField
      stateAccessInitial={stateAccessInitial}
      renderSet={renderSet}
      parentPath={parentPath}
      {...props}
    />
  );
};
export const renderMonthsInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  return (
    <MonthsField
      stateAccessInitial={stateAccessInitial}
      renderSet={renderSet}
      parentPath={parentPath}
      {...props}
    />
  );
};

export const renderYearInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  return (
    <YearField
      stateAccessInitial={stateAccessInitial}
      renderSet={renderSet}
      parentPath={parentPath}
      {...props}
    />
  );
};

export const renderCurrencyInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  return (
    <DecimalField
      currencySymbol={"$"}
      stateAccessInitial={stateAccessInitial}
      renderSet={renderSet}
      parentPath={parentPath}
      {...props}
    />
  );
};

export const renderSimpleCurrencyInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet
) => {
  let value = stateAccessInitial.get(props.path);
  value = value ? parseFloat(value) : value;
  return (
    <CurrencyTextField
      variant="outlined"
      label={props.label}
      size="small"
      currencySymbol="$"
      outputFormat="number"
      fullWidth
      readOnly={true}
      id={`${props?.path?.join(".")}:${props?.label}`}
      value={value ?? ""}
    />
  );
};
export const renderSimpleNumberInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet
) => {
  const value = stateAccessInitial.get(props.path);
  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      value={typeof value === "number" ? value : " "}
      name={props.name}
      fullWidth
      label={props.label}
      variant="outlined"
      size="small"
      InputProps={{ style: { textAlign: "right" } }}
    />
  );
};

export const renderSimpleYearInputField = <T extends any>(
  props: NumberInputField<T>,
  stateAccessInitial: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      value={stateAccessInitial.get(props.path) || " "}
      inputProps={{
        readOnly: true
      }}
      name={props.name}
      fullWidth
      label={props.label}
      variant="outlined"
      size="small"
      InputProps={{ style: { textAlign: "right" } }}
    />
  );
};
export const renderDateField = <T extends any>(
  props: DateField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  const { valid, errors } = validateSchema(
    renderSet?.schema as any,
    stateAccess.get(props.path),
    buildSchemaPath(parentPath ?? [], props.path, stateAccess, renderSet.schema)
  );
  const error = stateAccess.get(props.path) && valid === false;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={`${props?.path?.join(".")}:${props?.label}`}
        name={props.name}
        error={
          error
            ? true
            : stateAccess.get(props.path)
            ? !isValidDate(stateAccess.get(props.path))
            : false
        }
        helperText={
          stateAccess.get(props.path)
            ? isValidDate(stateAccess.get(props.path))
              ? error
                ? errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
                : undefined
              : "Invalid date"
            : undefined
        }
        autoOk
        fullWidth
        minDate={props?.minDate ? props?.minDate(stateAccess) : undefined}
        disableFuture={props?.disableRegion === "future" ? true : undefined}
        disablePast={props?.disableRegion === "past" ? true : undefined}
        InputLabelProps={{ shrink: true }}
        inputVariant="filled"
        variant="inline"
        label={props.label}
        required={
          typeof props.required === "function"
            ? props.required(stateAccess)
            : props.required ?? false
        }
        format="MM/dd/yyyy"
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        size="small"
        value={stateAccess.get(props.path) ?? null}
        onChange={(value) => {
          if (isValidDate(value) && value) {
            value.setHours(0);
            value.setMinutes(0);
            value.setMilliseconds(0);
            stateAccess.set(props.path, value?.toISOString());
          } else stateAccess.set(props.path, value ? value.toString() : null);
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
function SSNInput(props: any) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      id={`${props?.path?.join(".")}:${props?.label}`}
      options={{
        numericOnly: true,
        blocks: [0, 3, 2, 4],
        delimiters: ["", "-", "-"]
      }}
      title={`
        SSN cannot
        begin with 666 or 900-999,
        be 078-05-1120 or 219-09-9999,
        contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
        or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
      `}
      //eslint-disable-next-line
      // pattern="(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}"
      {...rest}
    />
  );
}
export const RenderSSNField = <T extends any>(
  { name, path, label, icon, required = false, type, width = "1/2" }: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  const validation = validateSchema(
    renderSet?.schema as any,
    stateAccess.get(path)?.replace(/[- ]/g, ""),
    buildSchemaPath(parentPath ?? [], path, stateAccess, renderSet.schema)
  );

  const error = stateAccess.get(path) && validation?.valid === false;
  return (
    <TextField
      id={`${path?.join(".")}:${label}`}
      size="small"
      variant="filled"
      label={label}
      required={typeof required === "function" ? required(stateAccess) : required}
      type={type}
      fullWidth
      InputProps={{ inputComponent: SSNInput }}
      InputLabelProps={{ shrink: true }}
      value={stateAccess.get(path) ?? ""}
      onChange={(event) => {
        if (event.target.value) {
          stateAccess.set(path, event.target.value.replace(/[- ]/g, ""));
        } else {
          stateAccess.set(path, null);
        }
      }}
      error={error}
      helperText={
        error ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "") : undefined
      }
    />
  );
};
export const formatSimpleSSN = (ssn: string) =>
  ssn?.length === 9
    ? `${ssn?.substring(0, 3)}-${ssn?.substring(3, 5)}-${ssn?.substring(5, 9)}`
    : ssn;

const SSNStateField = <T extends any>(
  props: TextInputField<T> & { stateAccess: StateAccess; renderSet: RenderSet }
) => {
  const [show, setShow] = useState(false);
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <TextField
          id={`${props?.path?.join(".")}:${props?.label}`}
          value={
            show
              ? props.valueToString
                ? props.valueToString(formatSimpleSSN(props.stateAccess.get(props.path)))
                : formatSimpleSSN(props.stateAccess.get(props.path))
              : props.valueToString
              ? "*** ** " +
                props.valueToString(
                  props.stateAccess?.get(props.path)?.toString()?.substring(7, 11)
                )
              : "*** ** " + props.stateAccess?.get(props.path)?.toString()?.substring(7, 11)
          }
          inputProps={{
            readOnly: true
          }}
          name={props.name}
          fullWidth
          label={props.label}
          variant="outlined"
          size="small"
        />
      </Box>
      <Box>
        <IconButton id="show-ssn-button" onClick={() => setShow(!show)}>
          {show ? (
            <HintTooltip title="Click here to hide applicant's SSN.">
              <VisibilityOffIcon />
            </HintTooltip>
          ) : (
            <HintTooltip title="Click here to show applicant's full SSN">
              <VisibilityIcon />
            </HintTooltip>
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export const RenderSimpleSSN = <T extends any>(
  props: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return <SSNStateField renderSet={renderSet} stateAccess={stateAccess} {...props} />;
};
const formatValue = (
  event:
    | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  props: {
    digitsOnlyField: boolean;
    upperCase?: boolean;
    removeEmptySpaces?: boolean;
  }
) => {
  let value = event.target.value.trimStart();
  if (props.upperCase) value = value.toUpperCase();
  if (props?.removeEmptySpaces) value = removeSpaces(value);
  value = value.replace(/["]+/g, "");
  return value;
};
const TextInput = <T extends any>(
  props: TextInputField<T> & {
    stateAccess: StateAccess;
    renderSet: RenderSet;
    digitsOnlyField: boolean;
    upperCase?: boolean;
    parentPath?: ParentPath<T>;
  }
) => {
  const globalState = props.stateAccess.get(props.path);
  const [state, setState] = useState(globalState);
  const [validation, setValidation] = useState(
    globalState
      ? validateSchema(
          props?.renderSet?.schema as any,
          state,
          buildSchemaPath(
            props?.parentPath ?? [],
            props.path,
            props.stateAccess,
            props.renderSet.schema
          )
        )
      : { valid: true }
  );
  const [timeout, setTimeoutState] = useState<undefined | NodeJS.Timeout>();
  useEffect(() => {
    if (globalState !== state) setState(globalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState]);
  const error = validation?.valid === false;
  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      size="small"
      variant="filled"
      label={props.label}
      required={
        typeof props.required === "function"
          ? props.required(props.stateAccess)
          : props.required ?? false
      }
      type={props.type === "password" ? "text" : props.type}
      fullWidth
      multiline={props?.multiline}
      minRows={props?.rows}
      value={state ?? undefined}
      inputProps={{
        ...props?.inputProps,
        minLength: props.minLength,
        maxLength: props.maxLength,
        // @ts-ignore
        style: props.type === "password" ? { "-webkit-text-security": "disc" } : {}
      }}
      InputLabelProps={{ shrink: true }}
      autoComplete="new-password"
      onBlur={(event) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        const value = formatValue(event, props);
        setValidation(
          validateSchema(
            props?.renderSet?.schema as any,
            value,
            buildSchemaPath(
              props?.parentPath ?? [],
              props.path,
              props.stateAccess,
              props.renderSet.schema
            )
          )
        );
        props.stateAccess.set(props.path, value === "" ? null : value);
      }}
      onChange={(event) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        const value = formatValue(event, props);
        setState(value);
        setTimeoutState(
          setTimeout(() => {
            setValidation(
              validateSchema(
                props?.renderSet?.schema as any,
                value,
                buildSchemaPath(
                  props?.parentPath ?? [],
                  props.path,
                  props.stateAccess,
                  props.renderSet.schema
                )
              )
            );
            props.stateAccess.set(props.path, value === "" ? null : value);
          }, 500)
        );
      }}
      error={error}
      helperText={
        error ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "") : undefined
      }
    />
  );
};
const MultiTextInput = <T extends any>(
  props: MultiTextInputField<T> & {
    stateAccess: StateAccess;
    renderSet: RenderSet;
    digitsOnlyField: boolean;
    upperCase?: boolean;
    parentPath?: ParentPath<T>;
  }
) => {
  const { enqueueSnackbar } = useSnackbar();
  const globalState = props.stateAccess.get(props.path);
  const [state, setState] = useState<string[]>(globalState);
  const [current, setCurrent] = useState("");
  const [validation, setValidation] = useState(
    globalState
      ? validateSchema(
          props?.renderSet?.schema as any,
          state,
          buildSchemaPath(
            props?.parentPath ?? [],
            props.path,
            props.stateAccess,
            props.renderSet.schema
          )
        )
      : { valid: true }
  );
  //TODO: Fix event type
  const handleKeyUp = (e: any) => {
    const value = formatValue(e, props);
    if (e.keyCode === 13 && value?.trim() !== "") {
      setState((oldState) => {
        props.stateAccess.set(props.path, [...(oldState ?? []), current]);
        return [...(oldState ?? []), value];
      });
      setCurrent("");
    }
  };
  const handleAdd = () => {
    if (current) {
      if (props.type === "email" && !/\S+@\S+\.\S+/.test(current)) {
        enqueueSnackbar("Value should be an email!", {
          variant: "error"
        });
        return;
      }

      setState((oldState) => {
        props.stateAccess.set(props.path, [...(oldState ?? []), current]);
        return [...(oldState ?? []), current];
      });
      setCurrent("");
    }
  };

  const handleDelete = (index: number) => {
    const newState = [...state];
    newState.splice(index, 1);
    setState(newState);
    props.stateAccess.set(props.path, newState);
  };
  const [timeout, setTimeoutState] = useState<undefined | NodeJS.Timeout>();
  useEffect(() => {
    if (globalState !== state) setState(globalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState]);
  const error = validation?.valid === false;
  const handleDeleteAll = () => {
    setState([]);
    props.stateAccess.set(props.path, []);
  };
  return (
    <FormControl fullWidth={true}>
      <div style={{ maxWidth: "fit-content" }}>
        {props.stateAccess.get(props.path)?.map((item: any, index: number) => (
          <Chip
            style={{ margin: "5px" }}
            key={index}
            size="small"
            onDelete={() => handleDelete(index)}
            label={item}
          />
        ))}
      </div>
      <TextField
        id={`${props?.path?.join(".")}:${props?.label}`}
        size="small"
        onKeyDown={handleKeyUp}
        variant="filled"
        label={props.label}
        required={
          typeof props.required === "function"
            ? props.required(props.stateAccess)
            : props.required ?? false
        }
        type={props.type}
        fullWidth
        value={current ?? null}
        inputProps={{
          ...props?.inputProps,
          minLength: props.minLength,
          maxLength: props.maxLength
        }}
        InputLabelProps={{ shrink: true }}
        autoComplete="new-password"
        onBlur={(event) => {
          if (timeout) clearTimeout(timeout);
          const value = formatValue(event, props);
          setValidation(
            validateSchema(
              props?.renderSet?.schema as any,
              value,
              buildSchemaPath(
                props?.parentPath ?? [],
                props.path,
                props.stateAccess,
                props.renderSet.schema
              )
            )
          );
        }}
        onChange={(event) => {
          if (timeout) clearTimeout(timeout);
          const value = formatValue(event, props);
          setCurrent(value);
          setTimeoutState(
            setTimeout(() => {
              setValidation(
                validateSchema(
                  props?.renderSet?.schema as any,
                  value,
                  buildSchemaPath(
                    props?.parentPath ?? [],
                    props.path,
                    props.stateAccess,
                    props.renderSet.schema
                  )
                )
              );
            }, 500)
          );
        }}
        error={error}
        helperText={
          error
            ? validation?.errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
            : undefined
        }
      />
      <Box component={"div"} display="flex" justifyContent="flex-end">
        <Button onClick={handleAdd}>Add</Button>
        <Button onClick={handleDeleteAll}>Clear</Button>
      </Box>
    </FormControl>
  );
};
export const MultiPhoneInput = <T extends any>(
  props: MultiTextInputField<T> & {
    stateAccess: StateAccess;
    renderSet: RenderSet;
    upperCase?: boolean;
    parentPath?: ParentPath<T>;
  }
) => {
  const globalState = props.stateAccess.get(props.path);
  const [state, setState] = useState<string[]>(globalState);
  const [current, setCurrent] = useState("");
  const { valid, errors } = validateSchema(
    props.renderSet?.schema as any,
    props.stateAccess.get(props.path),
    buildSchemaPath(
      props?.parentPath ?? ([] as ParentPath<T>),
      props.path,
      props.stateAccess,
      props.renderSet.schema
    )
  );
  const error = props.stateAccess.get(props.path) && valid === false;
  //TODO: Fix event type
  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13 && e.target.value?.trim() !== "") {
      setState((oldState) => {
        props.stateAccess.set(props.path, [...(oldState ?? []), e.target.value]);
        return [...(oldState ?? []), e.target.value];
      });
      setCurrent("");
    }
  };
  const handleAdd = () => {
    setState((oldState) => {
      props.stateAccess.set(props.path, [...(oldState ?? []), current]);
      return [...(oldState ?? []), current];
    });
    setCurrent("");
  };
  const handleDelete = (index: number) => {
    const newState = [...(state ?? [])];
    newState.splice(index, 1);
    setState(newState);
    props.stateAccess.set(props.path, newState);
  };
  useEffect(() => {
    if (globalState !== state) setState(globalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState]);

  const handleDeleteAll = () => {
    setState([]);
    props.stateAccess.set(props.path, []);
  };
  return (
    <FormControl fullWidth={true}>
      <div style={{ maxWidth: "fit-content" }}>
        {state?.map((item: any, index: number) => (
          <Chip
            style={{ margin: "5px" }}
            key={index}
            size="small"
            onDelete={() => handleDelete(index)}
            label={props.valueToString ? props.valueToString(item) : item}
          />
        ))}
      </div>
      <MuiPhoneNumber
        id={`${props.path?.join(".")}:${props.label}`}
        size="small"
        onKeyDown={handleKeyUp}
        variant="filled"
        label={props.label}
        required={props.required}
        fullWidth
        error={error}
        helperText={
          error ? errors?.reduce((acc, curr) => acc + "\n" + curr.message, "") : undefined
        }
        placeholder="(XXX) XXX-XXXX"
        onlyCountries={["us"]}
        disableCountryCode={true}
        defaultCountry={"us"}
        value={current ?? null}
        onChange={(value: any) => {
          setCurrent(value !== "" ? value?.replace(/\s|\+1|\(|\)|-/g, "") : null);
        }}
      />
      <Box component={"div"} display="flex" justifyContent="flex-end">
        <Button onClick={handleAdd}>Add</Button>
        <Button onClick={handleDeleteAll}>Clear</Button>
      </Box>
    </FormControl>
  );
};
export const renderTextInputField = <T extends any>(
  props: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  digitsOnlyField = false,
  parentPath?: ParentPath<T>
) => {
  return (
    <TextInput
      stateAccess={stateAccess}
      renderSet={renderSet}
      digitsOnlyField={digitsOnlyField}
      parentPath={parentPath}
      {...props}
    />
  );
};

export const renderUpperCaseTextInputField = <T extends any>(
  props: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  digitsOnlyField = false,
  parentPath?: ParentPath<T>
) => {
  return (
    <TextInput
      stateAccess={stateAccess}
      renderSet={renderSet}
      digitsOnlyField={digitsOnlyField}
      upperCase={true}
      parentPath={parentPath}
      {...props}
    />
  );
};

export const renderZipCodeField = <T extends any>(
  props: ZipCodeField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return <ZipCode stateAccess={stateAccess} props={props} renderSet={renderSet} />;
};

export const renderPhoneInputField = <T extends any>(
  { name, path, label, icon, required = false, type, width = "1/2" }: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  const { valid, errors } = validateSchema(
    renderSet?.schema as any,
    stateAccess.get(path),
    buildSchemaPath(parentPath ?? ([] as ParentPath<T>), path, stateAccess, renderSet.schema)
  );
  const error = stateAccess.get(path) && valid === false;
  return (
    <MuiPhoneNumber
      id={`${path?.join(".")}:${label}`}
      size="small"
      variant="filled"
      label={label}
      required={required}
      fullWidth
      error={error}
      helperText={error ? errors?.reduce((acc, curr) => acc + "\n" + curr.message, "") : undefined}
      placeholder="(XXX) XXX-XXXX"
      onlyCountries={["us"]}
      disableCountryCode={true}
      defaultCountry={"us"}
      value={stateAccess.get(path) ?? null}
      onChange={(value: any) => {
        stateAccess.set(path, value !== "" ? value?.replace(/\s|\+1|\(|\)|-/g, "") : null);
      }}
    />
  );
};

export const renderSimplePhoneInputField = <T extends any>(
  { name, path, label, icon, required = false, type, width = "1/2" }: TextInputField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <MuiPhoneNumber
      id={`${path?.join(".")}:${label}`}
      size="small"
      variant="outlined"
      label={label}
      required={required}
      fullWidth
      placeholder="(XXX) XXX-XXXX"
      onlyCountries={["us"]}
      disableCountryCode={true}
      defaultCountry={"us"}
      value={stateAccess.get(path) ?? ""}
    />
  );
};

export const renderModel = <T extends any>(
  model: Model<T>,
  stateAccess: StateAccess,
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  user?: User,
  formEditContext?: FormEditContext
) => {
  return (
    <div className="renderModel">
      {model.entities.map((x, index) => {
        const { show = () => true } = x;
        return show(stateAccess, formEditContext, mainStateAccess) ? (
          <React.Fragment key={index}>
            {generateForm(
              x,
              stateAccess,
              [],
              mainStateAccess,
              renderSet,
              permissions,
              formEditContext,
              user
            )}
          </React.Fragment>
        ) : null;
      })}
    </div>
  );
};

export const renderFilePreview = <T extends any>(
  props: filePreviewField<T>,
  stateAccess: StateAccess
) => {
  const file = stateAccess.get(props.path);
  const contentType = file?.metadata?.contentType ?? "";
  if (contentType.match("image.*")) {
    return <img src={file?.url} width="100%" alt="file-preview" />;
  }
  if (contentType === "application/pdf") {
    return (
      <div>
        <a target="_blank" rel="noopener noreferrer" href={file?.url ?? ""}>
          {file?.name}
        </a>
      </div>
    );
  }

  return <>{file?.name}</>;
};

export const RenderFormListModel = <T extends any>(
  {
    renderOrder,
    path,
    required,
    entity,
    name,
    search = () => true,
    elevation = 0,
    style = {}
  }: ListModel<T>,
  stateAccess: StateAccess,
  parentPath: ParentPath<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  user?: User,
  formEditContext?: FormEditContext
) => {
  const entities = stateAccess.get(path) ?? [];
  const handleAdd = () => {
    const defaultState = generateDefault(entity, {}, fillDefaultsByPath);
    stateAccess.set(path, [...entities, defaultState]);
  };

  const handleDelete = (index: number) => () => {
    stateAccess.set(
      path,
      entities.filter((el: any, entityIndex: number) => index !== entityIndex)
    );
  };

  const id = mainStateAccess.get([])?._id;
  const searchState = useContext(SearchContext)[0][id] ?? "";

  const filteredEntities =
    searchState !== ""
      ? entities.reduce(
          (acc: any, curr: any, index: number) =>
            search(curr, searchState) ? [index, ...acc] : acc,
          []
        )
      : entities;

  const renderEntities = () => {
    return entities.map((modelEntity: Model<T>, index: number) => {
      return (
        <Paper
          key={`${modelEntity?.name}-${index}`}
          elevation={3}
          style={{
            padding: "10px",
            marginBottom: "20px",
            display: filteredEntities.includes(index) || !searchState ? "block" : "none"
          }}
        >
          <Box display="flex" alignContent="flex-start">
            <Box flexGrow={1}>
              {generateForm<T>(
                entity as FormComponent<T>,
                {
                  get: (defaultPath) =>
                    stateAccess.get((path as any).concat([index, ...defaultPath])),
                  set: (defaultPath, value) =>
                    stateAccess.set((path as any).concat([index, ...defaultPath]), value)
                },
                (parentPath as any).concat(path as any).concat([index]),
                mainStateAccess,
                renderSet,
                permissions,
                formEditContext,
                user
              )}
            </Box>
            <Box>
              {index !== 0 || !required ? (
                <IconButton
                  style={{
                    color: "#E34C28"
                  }}
                  aria-label={`remove ${name}`}
                  onClick={handleDelete(index)}
                >
                  <HintTooltip title={`Click here to remove the ${name}.`}>
                    <RemoveCircleIcon />
                  </HintTooltip>
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Paper>
      );
    });
  };

  return (
    <Paper
      elevation={elevation}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)",
        ...style
      }}
    >
      <Box style={{ display: "flex" }} key={name}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",
            fontWeight: "bold"
          }}
        >
          {name}
        </DialogContentText>
        <Box>
          <IconButton
            style={{
              color: "#50A538"
            }}
            aria-label={`add new ${name}`}
            onClick={handleAdd}
          >
            <HintTooltip title={`Click here to add new ${name}.`}>
              <AddCircleIcon />
            </HintTooltip>
          </IconButton>
        </Box>
      </Box>
      {renderOrder === "desc" ? renderEntities() : renderEntities().reverse()}
    </Paper>
  );
};

export const RenderShowListModel = <T extends any>(
  { path, entity, name, search = () => true }: ListModel<T> | ReadOnlyListModel<T>,
  stateAccess: StateAccess,
  parentPath: ParentPath<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  user?: User,
  formEditContext?: FormEditContext
) => {
  const entities = stateAccess.get(path) ?? [];

  const id = mainStateAccess.get([])?._id;
  const searchState = useContext(SearchContext)[0][id] ?? "";

  const filteredEntities =
    searchState !== ""
      ? entities.reduce(
          (acc: any, curr: any, index: number) =>
            search(curr, searchState) ? [index, ...acc] : acc,
          []
        )
      : entities;

  return (
    <div
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <>
        <Box style={{ display: "flex" }} key={name}>
          <DialogContentText
            style={{
              color: "#254e6e",
              fontSize: "19px",
              padding: "5px",
              fontWeight: "bold"
            }}
          >
            {name}
          </DialogContentText>
        </Box>
        {entities.length > 0 ? (
          entities.map((modelEntity: Model<T>, index: number) => {
            return (
              <Paper
                key={`${modelEntity?.name}-${index}`}
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "20px",
                  display: filteredEntities.includes(index) || !searchState ? "block" : "none"
                }}
              >
                <Box display="flex" alignContent="flex-start">
                  <Box flexGrow={1}>
                    {generateForm<T>(
                      entity as FormComponent<T>,
                      {
                        get: (defaultPath) =>
                          stateAccess.get((path as any).concat([index, ...defaultPath])),
                        set: (defaultPath, value) =>
                          stateAccess.set((path as any).concat([index, ...defaultPath]), value)
                      },
                      (parentPath as any).concat(path as any).concat([index]),
                      mainStateAccess,
                      renderSet,
                      permissions,
                      formEditContext,
                      user
                    )}
                  </Box>
                </Box>
              </Paper>
            );
          })
        ) : (
          <i>No {name.toLowerCase()}</i>
        )}
      </>
    </div>
  );
};
export const renderSegment = <T extends any>(
  segment: Segment<T>,
  stateAccess: StateAccess,
  path: Path<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  user?: User,
  formEditContext?: FormEditContext
) => {
  return (
    <RenderSegment<T>
      segment={segment}
      stateAccess={stateAccess}
      path={path}
      mainStateAccess={mainStateAccess}
      renderSet={renderSet}
      permissions={permissions}
      user={user}
      formEditContext={formEditContext}
    />
  );
};
export const RenderSegment = <T extends any>({
  segment,
  stateAccess,
  path,
  mainStateAccess,
  renderSet,
  permissions,
  user,
  formEditContext
}: {
  segment: Segment<T>;
  stateAccess: StateAccess;
  path: Path<T>;
  mainStateAccess: StateAccess;
  renderSet: RenderSet;
  permissions?: Permission;
  user?: User;
  formEditContext?: FormEditContext;
}) => {
  const [locked, setLocked] = useState(segment.locked || false);
  const [openPINDialog, setOpenPINDialog] = useState(false);
  const children = segment.entities
    .map((x, index) => {
      const { show = () => true } = x;
      const form = generateForm(
        x,
        stateAccess,
        path,
        mainStateAccess,
        renderSet,
        permissions,
        formEditContext,
        user
      );
      return show(stateAccess, formEditContext, mainStateAccess)
        ? ["hidden-field", "virtual-field"].includes(x.formComponent)
          ? form
          : form && (
              <Grid
                item
                xs={calculateSize(x.width ?? "1/2", stateAccess)}
                key={`${x.name}-${index}`}
              >
                {form}
              </Grid>
            )
        : null;
    })
    .filter((x) => x);

  return children.length > 0 ? (
    <>
      <Paper
        id={path?.join(".")}
        elevation={segment.elevation ?? 0}
        style={{
          background: "transparent",
          marginBottom: `${segment.elevation ? segment.elevation * 2 : 0}px`,
          ...(typeof segment.locked === "boolean" ? { position: "relative" } : {}),
          ...segment.style
        }}
      >
        {segment.name !== undefined ? (
          <DialogContentText
            style={{
              color: "#254e6e",
              fontSize: "19px",
              margin: "5px 0px 10px 0px",
              fontWeight: "bold",
              // background: "rgb(29 61 88)",
              // color: "white",
              // paddingLeft: "10px",
              // borderRadius: "4px",
              ...segment.titleStyle
            }}
          >
            {typeof segment.name === "function" ? segment.name(stateAccess) : segment.name}
          </DialogContentText>
        ) : (
          <></>
        )}

        {typeof segment.locked === "boolean" && (
          <div style={{ position: "absolute", right: 0, top: 0 }}>
            <Switch
              checked={!locked}
              id={path?.join(".")}
              onChange={() => {
                if (!locked) {
                  setLocked(true);
                } else {
                  setOpenPINDialog(true);
                }
              }}
            />
          </div>
        )}
        <UnlockPINDialog
          open={openPINDialog}
          setOpen={setOpenPINDialog}
          userPIN={user?.data?.info?.PIN}
          setLocked={setLocked}
        />
        {!locked && (
          <Grid container spacing={1}>
            {children}
          </Grid>
        )}
      </Paper>
      {segment.separator && (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            background: "#727272",
            color: "white",
            fontWeight: "bold",
            padding: "5px 0px",
            margin: "10px 0",
            borderRadius: "3px"
          }}
        ></div>
      )}
    </>
  ) : (
    <></>
  );
};
const SimpleDurationField = <T extends any>({
  props,
  stateAccess,
  renderSet
}: {
  props: DurationField<T>;
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) => {
  return (
    <TextField
      value={
        stateAccess.get(props.end) && stateAccess.get(props.start)
          ? props.valueToString
            ? props.valueToString(
                calculateDuration(stateAccess.get(props.start), stateAccess.get(props.end))
              )
            : ""
          : "Unlimited"
      }
      id={`${props?.path?.join(".")}:${props?.label}`}
      inputProps={{
        readOnly: true
      }}
      name={props.name}
      fullWidth
      label={props.label}
      variant="outlined"
      size="small"
    />
  );
};
const SimpleTextInput = <T extends any>(
  props: Field<T> & {
    stateAccess: StateAccess;
    renderSet: RenderSet;
  }
) => {
  const valueToStringFunction = props.valueToString ?? ((x: any) => x);
  return (
    <TextField
      id={`${props?.path?.join(".")}:${props?.label}`}
      value={valueToStringFunction(props.stateAccess.get(props.path)) || " "}
      inputProps={{
        readOnly: true
      }}
      multiline={props?.multiline}
      name={props.name}
      fullWidth
      label={props.label}
      variant="outlined"
      size="small"
    />
  );
};
export const renderSimpleDurationField = <T extends any>(
  props: DurationField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return <SimpleDurationField props={props} stateAccess={stateAccess} renderSet={renderSet} />;
};
export const renderSimpleText = <T extends any>(
  { name, path, label, valueToString, multiline }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <SimpleTextInput
      stateAccess={stateAccess}
      renderSet={renderSet}
      multiline={multiline}
      name={name}
      path={path}
      label={label}
      valueToString={valueToString}
    />
  );
};

export const renderHidden = <T extends any>(
  { name, path, label, required, valueToString = (x: any) => x }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <input
      key={path.join() + label}
      value={valueToString(stateAccess.get(path)) || " "}
      type="hidden"
      required={typeof required === "function" ? required(stateAccess) : required}
      name={name}
    />
  );
};

export const renderVirtualField = <T extends any>(
  { name, path, label, required, value }: VirtualField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return <></>;
};

export const renderSimpleMultiSelect = <T extends any>(
  { name, path, label, valueToString = (x: any) => x }: Field<T>,
  stateAccess: StateAccess,
  parentPath: ParentPath<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      value={valueToString(stateAccess.get(path)) ?? " "}
      inputProps={{
        "data-value": JSON.stringify(stateAccess.get(path)),
        readOnly: true
      }}
      name={name}
      fullWidth
      label={label}
      variant="outlined"
      size="small"
    />
  );
};
export const renderSimpleMultiTextInputField = <T extends any>(
  { name, path, label, valueToString, multiline }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      value={valueToString ? valueToString(stateAccess.get(path)) ?? " " : " "}
      inputProps={{
        "data-value": JSON.stringify(stateAccess.get(path)),
        readOnly: true
      }}
      name={name}
      fullWidth
      label={label}
      variant="outlined"
      size="small"
    />
  );
};
export const renderSimpleMultiPhoneInputField = <T extends any>(
  { name, path, label, valueToString, multiline }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      value={valueToString ? valueToString(stateAccess.get(path)) ?? " " : " "}
      inputProps={{
        "data-value": JSON.stringify(stateAccess.get(path)),
        readOnly: true
      }}
      name={name}
      fullWidth
      label={label}
      variant="outlined"
      size="small"
    />
  );
};
export const renderSimpleSelect = <T extends any>(
  {
    path,
    label,
    name,
    style,
    options: { getOptionLabel },
    valueToString = (x: any) => x
  }: SelectField<T>,
  stateAccess: StateAccess,
  parentPath: ParentPath<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      id={`${path?.join(".")}:${label}`}
      value={getOptionLabel(stateAccess.get(path)) || " "}
      inputProps={{
        readOnly: true,
        style: style
      }}
      name={name}
      fullWidth
      label={label}
      variant="outlined"
      size="small"
    />
  );
};

export const renderSimpleDate = <T extends any>(
  { path, label }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <TextField
      id={`${path?.join(".")}:${label}`}
      name={toCamelCase(label)}
      value={
        stateAccess.get(path) ? new Date(stateAccess.get(path)).toLocaleDateString("en-US") : " "
      }
      inputProps={{
        readOnly: true
      }}
      fullWidth
      label={label}
      variant="outlined"
      size="small"
    />
  );
};

export const renderSimpleDateWithAge = <T extends any>(
  { path, label }: Field<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <TextField
          id={`${path?.join(".")}:${label}`}
          name="ageDatePicker"
          value={new Date(stateAccess.get(path))?.toLocaleDateString() ?? " "}
          inputProps={{
            readOnly: true
          }}
          fullWidth
          label={label}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={calculateAge(stateAccess.get(path))}
          inputProps={{
            readOnly: true
          }}
          fullWidth
          label="Age"
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

function calculateAge(date: Date | null | undefined | string) {
  if (date === null || date === undefined || date === "Invalid Date") return "";

  const currentDay = new Date();
  const currentYear = currentDay.getUTCFullYear();
  const currentMonth = currentDay.getMonth();

  const age = new Date(date);
  const years = currentYear - age.getUTCFullYear();
  const months = currentMonth - age.getMonth();
  if (months < 0 || (months === 0 && currentDay.getDate() < age.getDate())) return years - 1;

  return years;
}
function calculateDuration(startDate: Date, endDate: Date) {
  if (startDate && endDate) {
    return new Date(endDate)?.getUTCFullYear() - new Date(startDate)?.getUTCFullYear();
  }
}
export const renderRawField = <T extends any>(
  props: RawField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  return <SimpleRawField renderSet={renderSet} stateAccess={stateAccess} props={props} />;
};
const SimpleRawField = <T extends any>({
  props,
  stateAccess,
  renderSet
}: {
  props: RawField<T>;
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog open={open}>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            color: "black",
            padding: "13px",
            borderRadius: "5px",
            fontSize: "10px"
          }}
        >
          {JSON.stringify(stateAccess.get(props.path), null, 2)}
        </pre>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)} color="primary" variant="contained">
        Show Raw
      </Button>
    </>
  );
};
export const isValidDate = (date: any) => date && !isNaN(new Date(date).getTime());

export const renderDateWithAge = <T extends any>(
  { path, label, disableRegion, required }: DateWithAge<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet,
  parentPath?: ParentPath<T>
) => {
  const { valid, errors } = validateSchema(
    renderSet?.schema as any,
    stateAccess.get(path),
    buildSchemaPath(parentPath ?? [], path, stateAccess, renderSet.schema)
  );
  const error = stateAccess.get(path) && valid === false;
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            autoOk
            variant="inline"
            disableFuture={disableRegion === "future"}
            disablePast={disableRegion === "past"}
            inputVariant="filled"
            InputLabelProps={{ shrink: true }}
            required={typeof required === "function" ? required(stateAccess) : required ?? false}
            name="ageDatePicker"
            label={label}
            error={
              error ? true : stateAccess.get(path) ? !isValidDate(stateAccess.get(path)) : false
            }
            helperText={
              stateAccess.get(path)
                ? isValidDate(stateAccess.get(path))
                  ? error
                    ? errors?.reduce((acc, curr) => acc + "\n" + curr.message, "")
                    : undefined
                  : "Invalid date"
                : undefined
            }
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            size="small"
            value={stateAccess.get(path)}
            onChange={(value) => {
              if (isValidDate(value)) stateAccess.set(path, value?.toISOString());
              else stateAccess.set(path, value ? value.toString() : null);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={calculateAge(stateAccess.get(path))}
          fullWidth
          label="Age"
          variant="filled"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export const renderTabListModel = <T extends any>(
  props: TabListModel<T>,
  stateAccess: StateAccess,
  parentPath: ParentPath<T>,
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  user?: User,
  formEditContext?: FormEditContext
) => {
  return (
    <TabListModelRenderer
      props={props}
      stateAccess={stateAccess}
      parentPath={parentPath}
      mainStateAccess={mainStateAccess}
      renderSet={renderSet}
      permissions={permissions}
      user={user}
      formEditContext={formEditContext}
    />
  );
};

const StyledTabs = withStyles({
  flexContainer: {
    flexWrap: "wrap"
  }
})(Tabs);

const TabListModelRenderer = <T extends any>({
  props,
  stateAccess,
  parentPath,
  mainStateAccess,
  renderSet,
  permissions,
  user,
  formEditContext
}: {
  props: TabListModel<T>;
  stateAccess: StateAccess;
  parentPath: ParentPath<T>;
  mainStateAccess: StateAccess;
  renderSet: RenderSet;
  permissions?: Permission;
  user?: User;
  formEditContext?: FormEditContext;
}) => {
  const [value, setValue] = useState<false | number>(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const entities = stateAccess.get(props.path) ?? [];
  const handleAdd = () => {
    const restrictionResult = props?.addRestriction?.(stateAccess);

    if (!restrictionResult || restrictionResult.canAdd) {
      const defaultState = generateDefault(props.entity, {}, fillDefaultsByPath);
      setValue(entities.length);
      stateAccess.set(props.path, [...entities, defaultState]);
    } else {
      alert(restrictionResult.message);
    }
  };

  const handleDelete = (index: number) => () => {
    stateAccess.set(
      props.path,
      entities.filter((el: any, entityIndex: number) => index !== entityIndex)
    );
    setValue(false);
  };

  const id = mainStateAccess.get([])?._id;
  const searchState = useContext(SearchContext)[0][id] ?? "";

  const filteredEntities =
    searchState !== ""
      ? entities.reduce(
          (acc: any, curr: any, index: number) =>
            props.search ? (props.search(curr, searchState) ? [index, ...acc] : acc) : () => true,
          []
        )
      : entities;
  const renderEntities = () => {
    return entities.map((modelEntity: Model<T>, index: number) => {
      return (
        <div
          tabIndex={0}
          key={`${modelEntity.name}-${index}`}
          role="tabpanel"
          hidden={value !== index}
          id={`${modelEntity.name}-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          <Paper
            elevation={3}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: filteredEntities.includes(index) || !searchState ? "block" : "none"
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                {generateForm<T>(
                  props.entity as FormComponent<T>,
                  {
                    get: (defaultPath) =>
                      stateAccess.get((props.path as any).concat([index, ...defaultPath])),
                    set: (defaultPath, value) =>
                      stateAccess.set((props.path as any).concat([index, ...defaultPath]), value)
                  },
                  (parentPath as any).concat(props.path as any).concat([index]),
                  mainStateAccess,
                  renderSet,
                  permissions,
                  formEditContext,
                  user
                )}
              </Box>
              {renderSet.type === "edit" && (
                <Box>
                  {index !== 0 || !props.required ? (
                    <IconButton
                      style={{
                        color: "#E34C28"
                      }}
                      aria-label={`remove ${props.name}`}
                      onClick={handleDelete(index)}
                    >
                      <HintTooltip title={`Click here to remove the ${props.name}.`}>
                        <RemoveCircleIcon />
                      </HintTooltip>
                    </IconButton>
                  ) : null}
                </Box>
              )}
            </Box>
          </Paper>
        </div>
      );
    });
  };

  return (
    <div
      id={props.name}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex" }} key={props.name}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",
            fontWeight: "bold"
          }}
        >
          {props.name}
        </DialogContentText>
        {renderSet.type === "edit" && (
          <Box>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new ${props.name}`}
              onClick={handleAdd}
            >
              <HintTooltip title={`Click here to add new ${props.name}.`}>
                <AddCircleIcon />
              </HintTooltip>
            </IconButton>
            {props.additionalOptions && props.additionalOptions(stateAccess)}
          </Box>
        )}
      </Box>
      {entities.length > 0 ? (
        <div>
          <AppBar position="relative">
            <StyledTabs
              selectionFollowsFocus
              value={value}
              onChange={handleChange}
              aria-label="tab-list"
              variant="scrollable"
              scrollButtons="on"
            >
              {entities.map((modelEntity: any, index: number) => (
                <Tab
                  label={props.getTabName(modelEntity)}
                  key={`tab-${modelEntity.name}-${index}`}
                  id={`tab-${props.getTabName(modelEntity)}`}
                  aria-controls={`tabpanel-${index}`}
                />
              ))}
            </StyledTabs>
          </AppBar>
          {renderEntities()}
        </div>
      ) : renderSet.type === "show" ? (
        <i>No {props.name.toLowerCase()}</i>
      ) : (
        <></>
      )}
    </div>
  );
};

export const renderTimeline = <T extends any>(
  props: TimelineField<T>,
  stateAccess: StateAccess,
  renderSet: RenderSet
) => {
  const items = props.items(stateAccess);
  const minMax = items.reduce(
    (acc, curr) => {
      const currMin = curr.data[0].y[0];
      const currMax = curr.data[0].y[1];

      acc.min = acc.min < currMin ? acc.min : currMin;
      acc.max = acc.max > currMax ? acc.max : currMax;
      return acc;
    },
    { min: Infinity, max: 0 }
  );
  return (
    <div style={{ padding: "10px", border: "1px solid #ddd" }}>
      <ReactApexChart
        options={{
          grid: {
            padding: {
              left: 0,
              right: 0
            }
          },
          chart: {
            height: 100,
            type: "rangeBar",
            zoom: {
              enabled: false
            },
            events: {
              dataPointSelection: (event, chartContext, config) =>
                props.onTimelineDataClick
                  ? props.onTimelineDataClick(event, chartContext, config)
                  : undefined
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              rangeBarOverlap: false
            }
          },

          xaxis: {
            type: "datetime",
            ...minMax
          },
          tooltip: {
            x: {
              format: "dd MMM yyyy"
            }
          },
          stroke: {
            width: 1
          },
          fill: {
            type: "solid",
            opacity: 0.6
          },
          legend: {
            position: "top",
            horizontalAlign: "left"
          },
          dataLabels: {
            enabled: true,
            formatter: (val, opts) => opts.w.globals.seriesNames[opts.seriesIndex]
          }
        }}
        series={items}
        type="rangeBar"
        height={400}
      />
    </div>
  );
};

export const showRenderSet = (schema: RequestType | false): RenderSet => ({
  type: "show",
  schema: schema,
  radioInputRenderer: renderSimpleRadioField,
  listModelRenderer: RenderShowListModel,
  readOnlyListModelRenderer: RenderShowListModel,
  tabListModelRenderer: renderTabListModel,
  segmentRenderer: renderSegment,
  modelRenderer: renderModel,
  textInputRenderer: renderSimpleText,
  hiddenInputRenderer: renderHidden,
  virtualFieldRenderer: renderVirtualField,
  numberInputRenderer: renderSimpleNumberInputField,
  currencyInputRenderer: renderSimpleCurrencyInputField,
  percentageInputRenderer: renderSimpleNumberInputField,
  dateInputRenderer: renderSimpleDate,
  selectInputRenderer: renderSimpleSelect,
  multiTextInputFieldRenderer: renderSimpleMultiTextInputField,
  multiPhoneInputFieldRenderer: renderSimpleMultiPhoneInputField,
  multiSelectInputRenderer: renderSimpleMultiSelect,
  checkboxInputRenderer: renderSimpleCheckboxField,
  phoneInputRenderer: renderSimplePhoneInputField,
  dateWithAgeRenderer: renderSimpleDateWithAge,
  readOnlyRenderer: renderSimpleText,
  readOnlyNumberRenderer: renderSimpleCurrencyInputField,
  readOnlyPercentageRenderer: renderSimpleNumberInputField,
  SSNFieldRenderer: RenderSimpleSSN,
  zipCodeInputRenderer: renderSimpleText,
  filePreviewFieldRenderer: renderFilePreview,
  upperCaseTextInputRenderer: renderSimpleText,
  yearFieldRenderer: renderSimpleYearInputField,
  monthsFieldRenderer: renderSimpleNumberInputField,
  rawFieldRenderer: renderRawField,
  durationFieldRenderer: renderSimpleDurationField,
  readOnlydateInputRenderer: renderSimpleDate,
  timelineRenderer: renderTimeline,
  readOnlyMultiTextInputFieldRenderer: renderSimpleMultiTextInputField
});

export const editRenderSet = (schema: RequestType | false): RenderSet => ({
  type: "edit",
  schema: schema,
  radioInputRenderer: renderRadioField,
  listModelRenderer: RenderFormListModel,
  readOnlyListModelRenderer: RenderShowListModel,
  tabListModelRenderer: renderTabListModel,
  segmentRenderer: renderSegment,
  modelRenderer: renderModel,
  textInputRenderer: renderTextInputField,
  hiddenInputRenderer: renderHidden,
  virtualFieldRenderer: renderVirtualField,
  numberInputRenderer: renderNumberInputField,
  currencyInputRenderer: renderCurrencyInputField,
  percentageInputRenderer: renderPercentageField,
  dateInputRenderer: renderDateField,
  selectInputRenderer: renderSelectField,
  multiSelectInputRenderer: renderMultiSelectField,
  multiTextInputFieldRenderer: renderMultiTextInputField,
  multiPhoneInputFieldRenderer: renderMultiPhoneInputField,
  checkboxInputRenderer: renderCheckboxField,
  phoneInputRenderer: renderPhoneInputField,
  dateWithAgeRenderer: renderDateWithAge,
  readOnlyRenderer: renderSimpleText,
  readOnlyNumberRenderer: renderSimpleCurrencyInputField,
  readOnlyPercentageRenderer: renderSimpleNumberInputField,
  SSNFieldRenderer: RenderSSNField,
  zipCodeInputRenderer: renderZipCodeField,
  filePreviewFieldRenderer: renderFilePreview,
  upperCaseTextInputRenderer: renderUpperCaseTextInputField,
  yearFieldRenderer: renderYearInputField,
  monthsFieldRenderer: renderMonthsInputField,
  rawFieldRenderer: renderRawField,
  durationFieldRenderer: renderSimpleDurationField,
  readOnlydateInputRenderer: renderSimpleDate,
  timelineRenderer: renderTimeline,
  readOnlyMultiTextInputFieldRenderer: renderSimpleMultiTextInputField
});
