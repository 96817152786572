import Ajv, { ErrorObject } from "ajv";
import ajvErrors from "ajv-errors";
import traverse from "json-schema-traverse";
import { getAge, notifyWebhooksError } from "./src/helpers";
import { allSchemas } from "./src/schemas";
import { RequestType } from "./types";

const ajv = new Ajv({ allErrors: true, allowUnionTypes: true });
ajvErrors(ajv);

ajv.addFormat("age-restriction", {
  type: "string",
  validate: (val: string) => {
    const age = getAge(val);
    return age >= 16 && age <= 100;
  }
});

ajv.addFormat("date-time", {
  type: "string",
  validate: (val: string) => {
    return !isNaN(Date.parse(val));
  }
});

interface ValidateSchema {
  valid: boolean | Promise<unknown>;
  errors?: ErrorObject<string, Record<string, any>, unknown>[];
  warnings?: ErrorObject<string, Record<string, any>, unknown>[];
}

export function validateSchema(
  request: any,
  data: unknown,
  partialValidation?: (string | number | symbol)[]
): ValidateSchema {
  try {
    if (!request) return { valid: true, warnings: [], errors: [] };
    const schemaPath = partialValidation
      ?.map((item) =>
        typeof item === "string" && isNaN(parseInt(item))
          ? item.startsWith("anyOf")
            ? item
            : `properties/${item}`
          : "items"
      )
      ?.join("/");
    //@ts-ignore
    if (!allSchemas[request]) {
      if (process.env.DISCORD_WEBHOOK_URL) {
        notifyWebhooksError({
          requestName: request,
          message: "Schema not found"
        });
      }
      return {
        valid: true,
        errors: ["Schema not found!" as any],
        warnings: []
      };
    } else {
      //@ts-ignore
      traverse(allSchemas[request], {
        cb: (x) => {
          if (x.type === "object" && !x.additionalProperties) x.additionalProperties = false;
        }
      });
      //@ts-ignore
      ajv.compile(allSchemas[request]);
      const validate = ajv.getSchema(
        partialValidation
          ? //@ts-ignore
            `${allSchemas[request].$id}#/${schemaPath}`
          : //@ts-ignore
            `${allSchemas[request].$id}`
      );
      ///properties/data/properties/info/properties/aftermarketOptions/properties/insurances/items/anyOf/0/properties/type
      if (typeof validate === "function") {
        validate?.(data);

        const warnings =
          validate?.errors?.filter((error) => error.keyword === "additionalProperties") ?? [];

        const errors =
          validate?.errors?.filter((error) => error.keyword !== "additionalProperties") ?? [];

        if (warnings.length > 0) {
          notifyWebhooksError({
            requestName: request,
            warnings: warnings.map((el) => ({
              addProp: `${el.instancePath}/${el.params.additionalProperty}`
            }))
          });
        }

        return {
          valid: errors.length === 0,
          errors,
          warnings
        };
      } else {
        const warnings =
          request === "update_role" || request === "new_role"
            ? []
            : [
                {
                  instancePath: "",
                  schemaPath: partialValidation?.join("."),
                  keyword: "missingSchema",
                  params: {},
                  message: "Requested schema is missing."
                }
              ];
        if (warnings.length > 1) {
          notifyWebhooksError({
            requestName: request,
            warnings:
              request === "update_role" || request === "new_role"
                ? []
                : warnings.map((el) => ({
                    schemaPath: `${el.keyword}: ${el.schemaPath}`
                  }))
          });
        }
        return {
          valid: true,
          errors: [],
          // @ts-ignore
          warnings
        };
      }
    }
  } catch (error: any) {
    notifyWebhooksError({
      requestName: request,
      error: error.toString()
    });
    return {
      valid: true,
      errors: [],
      warnings: []
    };
  }
}
export default {
  validateSchema
};
