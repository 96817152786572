import { Dealership } from "components/Dealerships/types";
import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "../Roles/types";

export type DealershipPermissionsStruct = PermissionStruct<Required<Dealership>>;

export const dealershipPermissionStruct: DealershipPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      isActive: permissionNode("Is active"),
      funding: {
        businessDaysToFund: permissionNode("Business days to fund"),
        paymentMethod: permissionNode("Funding payment method"),
        enabled: permissionNode("Enabled")
      },
      coordinates: {
        lat: permissionNode("Latitute"),
        lon: permissionNode("Longitude")
      },
      applicationParserType: permissionNode("Application Parser Type"),
      eSign: permissionNode("E-Sign"),
      adminEmail: permissionNode("Admin email"),
      adminFirstName: permissionNode("Admin first name"),
      adminLastName: permissionNode("Admin last name"),
      adminRegistrationLink: permissionNode("Admin registration link"),
      isPlatform: permissionNode("Is platform"),
      uniqueDisplayName: permissionNode("Unique Display Name"),
      collateralTypes: {
        Automotive: permissionNode("Automotive"),
        Marine: permissionNode("Marine"),
        "Recreational Vehicles": permissionNode("Recreational Vehicles"),
        "Power Sport": permissionNode("Power Sport")
      },
      domain: permissionNode("Domain Name"),
      displayName: permissionNode("Display Name"),
      name: permissionNode("Name"),
      address: permissionNode("Address"),
      secondaryAddress: permissionNode("Secondary Address"),
      shippingAddress: permissionNode("shippingAddress"),
      city: permissionNode("City"),
      zipCode: permissionNode("Zip code"),
      relationType: permissionNode("Relation Type"),
      state: permissionNode("State"),
      county: permissionNode("County"),
      phone: permissionNode("Phone"),
      faxNumber: permissionNode("Fax number"),
      abbreviation: permissionNode("Abbreviation"),
      dealershipPrograms: multyPermissionNode("Dealership programs"),
      logo: {
        name: permissionNode("Name"),
        url: permissionNode("URL"),
        metadata: permissionNode("Metadata")
      },
      agreement: {
        dealershipSplit: permissionNode("Dealership split"),
        wfdSplit: permissionNode("Wfd split"),
        wfdGAPSplit: permissionNode("Wfd GAP split"),
        dealershipGAPSplit: permissionNode("Dealership split"),
        wfdWarrantySplit: permissionNode("Wfd Warranty split"),
        dealershipWarrantySplit: permissionNode("Dealership split")
      },
      titleDate: permissionNode("Title date")
    },
    bills: (permissionNode("Bills") as unknown) as any,
    chargebackBills: (permissionNode("Bills") as unknown) as any,
    representative: relationPermissionNode("Representative", "wfd_user"),
    representativeId: permissionNode("Relation Type"),
    customerCareAssignee: relationPermissionNode("Customer care assignee", "wfd_user"),
    customerCareAssigneeId: permissionNode("Customer care assignee id"),
    contacts: multyPermissionNode("Contacts"),
    contracts: relationPermissionNode("Contracts", "contract"),
    notes: multyPermissionNode("Notes"),
    customerCareNotes: multyPermissionNode("Customer care notes") as any,
    source: permissionNode("Source"),
    sourceId: permissionNode("Source Id"),
    netsuiteId: permissionNode("Netsuite Id"),
    hubspotId: permissionNode("Hubspot Id"),
    statistics: multyPermissionNode("Statistics")
  }
};
